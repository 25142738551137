import React, { useState, useEffect } from 'react';
import OneProduct from '../../../../helpers/one-product'
import { RECOMAND_ID, GET_PRODUCTS_LIST_BY_OPTION_ID } from '../../../../helpers/constants'
import CustomCarousel from "../../../../helpers/custom-carousel"
import Spinner from "../../../../helpers/spinner"
import axiosFetch from "../../../../helpers/axios"
import { withNamespaces } from "react-i18next";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import './styles.scss'

function FeaturedProducts(props) {
    const { t } = props
    const [isLoading, setIsLoading] = useState(false)
    const [productsList, setProductsList] = useState([])
    const productsArr = []

    useEffect(() => {
        const getProductsList = () => {
            setIsLoading(true)
            const formData = new FormData()
            formData.append("optionId", RECOMAND_ID)

            const productsResult = axiosFetch(GET_PRODUCTS_LIST_BY_OPTION_ID, formData)
            promiseResolved(productsResult)
        }
        getProductsList()
    }, [])

    const promiseResolved = (productsResult) => {
        Promise.resolve(productsResult).then((pInfo) => {
            setProductsList(pInfo)
        })
        setTimeout(() => {
            setIsLoading(false)
        }, 1000);

    }

    const renderProductsItems = (products) => {
        products.forEach((oneProduct, index) => {
            productsArr.push({
                oneProduct: <OneProduct oneProduct={oneProduct} className={"item"} showAddToCart={true} key={index} />,
            })
        })
    }

    const renderContent = () => {
        return (
            <div className="sidebar-carousel">
                {productsList && productsList.length > 0 && renderProductsItems(productsList)}
                <CustomCarousel swipeable={true}
                    draggable={true}
                    showDots={false}
                    arrows={true}
                    infinite={true}
                    autoPlay={true}
                    desktopItems={4}
                    autoPlaySpeedInSec={2}
                    carouselItems={productsArr}
                />
            </div>
        )
    }

    const content = isLoading ? <Spinner /> : renderContent()

    return (
        <div className="sidebar-widget hot-deals outer-bottom-xs">
            <h3 className="section-title">{t('Recommended_sets')}</h3>
            {content}
        </div>
    )
}

const mapStateToProps = (state) => ({});

export default withRouter(compose(connect(mapStateToProps, {}),withNamespaces())(FeaturedProducts));

