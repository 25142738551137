import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import "./styles.scss";
//setMinValue(e.target.value)
function Filters(props) {

  const { workZoneFilter, materialsFilter, lng, t, setCheckFilters } = props;

  const [checkedMaterials, setCheckedMaterials] = useState([])
  const [checkedWorkZone, setCheckedWorkZone] = useState([])

  useEffect(() => {
    setCheckFilters(checkedMaterials, checkedWorkZone)
  }, [checkedMaterials, checkedWorkZone])


  const setCommonFilterObjValue = (value, obj, setStateName) => {

    const index = obj.findIndex((item) => item === value)

    if (index < 0) {
      const data = [
        ...obj,
        value
      ]

      setStateName(data)
    } else {
      const newArray = [
        ...obj.slice(0, index),
        ...obj.slice(index + 1)
      ];
      setStateName(newArray)
    }

    const element = document.getElementById("categoryPage")
    const bodyRect = document.body.getBoundingClientRect()
    const elemRect = element.getBoundingClientRect()
    const needScrollToMyDivInPX = elemRect.top - bodyRect.top

    window.scrollTo({
      top: needScrollToMyDivInPX,
      behavior: 'smooth',
    })
  }
  //console.log("checkedMaterials->>> " , checkedMaterials)
  //console.log("checkedWorkZone ->>> " , checkedWorkZone)

  const renderMaterialsFilter = () => {
    const content = materialsFilter.map(({ id, titleRu, titleRo }) => {
      const finalTitle = lng === "ru" ? titleRu : titleRo
      return (
        <div>
          <FormControlLabel
            control={
              <Checkbox
                onChange={e => setCommonFilterObjValue(id, checkedMaterials, setCheckedMaterials)}
                name={finalTitle}
                color="primary"
              />
            }
            className="filter-checkbox"
            label={finalTitle}
          />
        </div>
      )
    })

    return content
  }

  const renderWorkZoneFilter = () => {
    const content = workZoneFilter.map(({ id, titleRu, titleRo }) => {
      const finalTitle = lng === "ru" ? titleRu : titleRo
      return (
        <div>
          <FormControlLabel
            control={
              <Checkbox
                onChange={e => setCommonFilterObjValue(id, checkedWorkZone, setCheckedWorkZone)}
                name={finalTitle}
                color="primary"
              />
            }
            className="filter-checkbox"
            label={finalTitle}
          />
        </div>
      )
    })

    return content
  }

  return (
    <div className="sidebar filter">
      <div className="sidebar-module-container">
        <h4 className="widget-title"> {t('Material_type')}</h4>
        {renderMaterialsFilter()}

        <h4 className="widget-title"> {t('Work_zone')}</h4>
        {renderWorkZoneFilter()}

      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  workZoneFilter: state.AddToCartReducer.workZoneFilter,
  materialsFilter: state.AddToCartReducer.materialsFilter,
  lng: state.PrefsReducer.lng,
});

export default withRouter(compose(connect(mapStateToProps, {
}), withNamespaces())(Filters));
