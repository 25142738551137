import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { GET_NEWS, NEWS_ITEMS_ON_THE_PAGE, COUNT_ALL_NEWS } from "../../../../helpers/constants"
import axiosFetch from "../../../../helpers/axios"
import { Link } from "react-router-dom"
import Pagination from '../../../../helpers/pagination';


import "./styles.scss"
function News(props) {
  const { lng, t, itemsOnPage = NEWS_ITEMS_ON_THE_PAGE } = props
  const [newsList, setNewsList] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [totalNews, setTotalNews] = useState([])

  useEffect(() => {
    const countAllNews = axiosFetch(COUNT_ALL_NEWS);
    Promise.resolve(countAllNews).then((arrList) => {
      setTotalNews(arrList)
    })
    getNews()
  }, [])


  const renderNewsContent = () => {
    const content = newsList.map((oneItem) => {
      const { id, date, image, titleRO, titleRU } = oneItem
      const finalTitle = lng === "ru" ? titleRU : titleRO
      //const finalDescription = lng === "ru" ? descriptionRU : descriptionRO
      return (
        <div className="col-md-4">
          <div class="blog-post  wow fadeInUp">

            <Link to={`/news/${id}`}>
              <div className="image-container" style={{ backgroundImage: `url(${image})` }} />
              <h1>{finalTitle}</h1>
            </Link>

            <span class="date-time">{date}</span>
            {/* <div
              className="news-description"
              dangerouslySetInnerHTML={{
                __html: finalDescription
              }}></div> */}
            <Link to={`/news/${id}`} class="btn btn-upper btn-primary read-more">
              {t('Read_more')}
            </Link>

          </div>
        </div>
      )
    })

    return content
  }

  const paginationClick = page => {
    setCurrentPage(page)
    getNews(page)
  }

  const getNews = (page) => {

    const fd = new FormData();

    fd.append('currentPage', page)
    fd.append('itemsOnPage', itemsOnPage)
    const newsResult = axiosFetch(GET_NEWS, fd)
    Promise.resolve(newsResult).then((arrList) => {
      setNewsList(arrList)
    })
  }


  return (
    <div className="cnt-home">
      {newsList.length > 0 && renderNewsContent()}
      <div style={{ clear: "both" }} />
      <div className="pagination-container">

        {totalNews > itemsOnPage && <Pagination
          onClick={paginationClick}
          total={totalNews}
          pageIndex={currentPage}
          pageSize={itemsOnPage}
        />}
      </div>

    </div>
  );

}

const mapStateToProps = (state) => ({
  lng: state.PrefsReducer.lng,
});

export default withRouter(compose(connect(mapStateToProps, {}), withNamespaces())(News));
